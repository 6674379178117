import React, { useState, useEffect, useRef } from "react"
import SimpleReactValidator from "simple-react-validator"

const UseCustomValidator = () => {

    const [customMobileValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => <p className="field_error" > {message} </p>,
        validators: {
            validateMobile: {
                message: 'The :attribute should be a valid mobile no.',
                rule: (val, params, validator) => {
                    if(parseInt(val) === 0 && val?.length >= 3 || val?.startsWith("000")) {
                        return false
                    } else {
                        return val
                    }
                },
                messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                required: false  // optional
            },
        }
    })))

    return { customMobileValidator }
}

export default UseCustomValidator