import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import toast from 'react-hot-toast'
import useScript from "../service/useScript"
import SimpleReactValidator from "simple-react-validator"
import API from "../api/index"
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import Thankyou from '../components/addon/thankYou'
import APIButton from "../components/addon/button/apiButton";
import { acceptOnlyNumbers } from "../service/helper"
import UseCustomValidator from "../service/simpleValidator"

const ContactEnquiry = (props) => {
    const metaTags = props?.data.allNodeTemplatePages.edges[0]?.node?.metatags ? props?.data.allNodeTemplatePages.edges[0].node.metatags : []
    const schema = props?.data.allNodeTemplatePages.edges[0]?.node?.relationships?.field_seo_schema ? props?.data.allNodeTemplatePages.edges[0]?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = props?.data.allNodeTemplatePages.edges[0].node.metatags_on_page || {}
    const [update, forceUpdate] = useState(false)
    const [thankYou, setThankYou] = useState(false)
    const { customMobileValidator } = UseCustomValidator()
    const formValidator = customMobileValidator
    const [btnloader, setBtnLoader] = useState(false)
    const [departmentList] = useState([
        {
            id: "Care@home Services",
            label: "Care@home Services"
        },
        {
            id: "Conferences & Events",
            label: "Conferences & Events"
        },
        {
            id: "Corporate Health",
            label: "Corporate Health"
        },
        {
            id: "Don't Know",
            label: "Don't Know"
        },
        {
            id: "Insurance & TPA",
            label: "Insurance & TPA"
        },
        {
            id: "International Patients",
            label: "International Patients"
        },
        {
            id: "Jobs",
            label: "Jobs"
        },
        {
            id: "Marketing",
            label: "Marketing"
        },
        {
            id: "Materials, Purchase",
            label: "Materials, Purchase"
        },
        {
            id: "Medical Education",
            label: "Medical Education"
        },
        {
            id: "Short Stay Services",
            label: "Short Stay Services"
        }
    ])
    let [formData, setFormData] = useState({
        webform_id: "enquiry",
        name: "",
        email_id: "",
        telephone_number: "",
        mobile_number: "",
        department: "Don't Know",
        enquiry_query: "",

    })

    const [captcha, setCaptcha] = useState("");
    const [captchaError, setcaptchaError] = useState(false)


    useEffect(() => {
        if (typeof window.initSelect !== "undefined") window.initSelect()
    }, [departmentList])


    const onFormDataChange = (event) => {
        const { id, value } = event?.target
        if(id === 'telephone_number' || id === 'mobile_number') {
            let val = acceptOnlyNumbers(value)
            setFormData({
                ...formData,
                [id]: val
            })
        } else {
            setFormData({
                ...formData,
                [id]: value
            })
        }
        
    }


    const onDepartmentChange = (event) => {
        setFormData({ ...formData, department: event.target.value })
    }

    const onSubmit = () => {
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
        } else {

            if (validateCaptcha(captcha) === true) {
                setBtnLoader(true)
                API.submitContactUsEnquiry(formData)
                    .then(response => {
                        setThankYou(true)
                        setCaptcha("")
                        resetForm()
                        window.scroll({ top: 0, behavior: "smooth" })
                        setBtnLoader(false)
                    })
                    .catch(err => {
                        toast.error("Something went wrong")
                        console.log(err)
                        setBtnLoader(false)
                    })
            }
            else {
                setcaptchaError(true)
                setCaptcha("");
                forceUpdate(!update)

            }


        }
    }


    const resetForm = () => {
        setFormData({
            webform_id: "enquiry",
            name: "",
            email_id: "",
            telephone_number: "",
            mobile_number: "",
            department: "",
            enquiry_query: ""
        })
        formValidator.current.hideMessages()
        forceUpdate(!update)
    }

    const resetThankyou = (value) => {
        if(window) window.location.reload();
    }


    useEffect(() => {
        if (thankYou === false) {
            loadCaptchaEnginge(6, "black", "white");
        }

    }, [thankYou])

    const handleCaptcha = (e) => {
        setcaptchaError(false);
        setCaptcha(e.target.value);
    }
    useScript("/assets/js/custom_select.js")

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: ["/assets/js/form-inputs-anim.js"],
                        css: [
                            "/assets/css/login.css",
                            "/assets/css/accessibility.css",
                        ],
                    }}
                    tags={{metaTags,canonicalUrl}}
                />
                <Schema schema={schema} />
                <main className="innerpage">
                    {
                        thankYou ? <Thankyou setThankYou={resetThankyou} /> : (
                            <section className="section_bg login_section pt-first-section">
                                <div className="container">
                                    <h2 className="section-heading">Enquiry</h2>
                                    <div className="row no-gutters">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="white_rounded_box">
                                                <div className="form_wrap">
                                                    <div className="form_block pl-0">
                                                        <p>Please specify your query below and we will revert back to you with the solution within no time.</p>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group -animated">
                                                                    <label>Full Name <span className="mandatory">*</span></label>
                                                                    <input
                                                                        onChange={onFormDataChange}
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={formData.name}
                                                                        id="name"
                                                                        name="name"
                                                                    />
                                                                    {formValidator.current.message('name', formData.name, 'required|alpha_space')}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group -animated">
                                                                    <label>Email Address <span className="mandatory">*</span></label>
                                                                    <input
                                                                        onChange={onFormDataChange}
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={formData.email_id}
                                                                        id="email_id"
                                                                        name="email_id"
                                                                    />
                                                                    {formValidator.current.message('email_id', formData.email_id, 'required|email')}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group -animated">
                                                                    <label>Landline No.</label>
                                                                    <input
                                                                        onChange={onFormDataChange}
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={formData.telephone_number}
                                                                        id="telephone_number"
                                                                        name="telephone_number"
                                                                    />
                                                                    {formData.telephone_number && formValidator.current.message('telephone_number', formData.telephone_number, 'required|numeric|min:0,num')}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group -animated">
                                                                    <label>Mobile No. <span className="mandatory">*</span></label>
                                                                    <input
                                                                        onChange={onFormDataChange}
                                                                        className="form-control"
                                                                        type="tel"
                                                                        value={formData.mobile_number}
                                                                        id="mobile_number"
                                                                        name="mobile_number"
                                                                    />
                                                                    {formValidator.current.message('mobile_number', formData.mobile_number, 'required|min:10|max:10|validateMobile')}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group select-dd">
                                                                    <select onChange={onDepartmentChange} name="" id=""
                                                                        className="form-control custom-select" placeholder="Select">
                                                                        {
                                                                            departmentList.map((el, index) => {
                                                                                return (
                                                                                    <option value={el.id} key={index}>{el.label}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-0">
                                                                    <LoadCanvasTemplate />
                                                                </div>
                                                                <div className="form-group -animated">
                                                                    <label>Type characters shown in the picture <span className="mandatory">*</span></label>
                                                                    <input className="form-control" type="text" value={captcha} onChange={handleCaptcha} id="captcha" />
                                                                    {formValidator.current.message('captcha', captcha, 'required')}
                                                                    {captchaError ? <p className="field_error">Invalid captcha value</p> : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group -animated">
                                                                    <label>Enter Your Query <span className="mandatory">*</span></label>
                                                                    <textarea
                                                                        onChange={onFormDataChange}
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={formData.enquiry_query}
                                                                        id="enquiry_query"
                                                                        name="enquiry_query"
                                                                    ></textarea>
                                                                    {formValidator.current.message('enquiry_query', formData.enquiry_query, 'required')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                            <APIButton 
                                                                title={`Submit`}
                                                                loading={btnloader}
                                                                disabled={btnloader}
                                                                onBtnClick={() => onSubmit()}  
                                                                className={`btn btn-primary`} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    }

                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
query ContactUsEnquiryFormQuery {
    allNodeTemplatePages(filter: {path: {alias: {regex: "/contact-us-enquiry$/"}}}) {
        edges {
          node {
            id
            title
            path {
              alias
            }
            relationships{
                field_seo_schema{
                    field_seo_title
                    field_seo_schema_text_area
                }
            }
            metatags: metatag_normalized {
              attributes {
                content
                name
                property
              }
            }
            metatags_on_page: field_meta_tags{
                canonical_url
              }
          }
        }
    }
  }  
`

export default ContactEnquiry